import React, { useState, useRef, useEffect, useCallback } from 'react';

const AiVideoClipsPlayer = ({ videoData }) => {
  const noOfAvatars = videoData.no_of_avatars;
  const avatarIds = [...new Set(videoData.ai_video_clips.map(clip => clip.ai_avatar_id))];

  const videoPlayerRefs = [...Array(noOfAvatars)].map(() => React.createRef());
  const currentVideoClipIndex = useRef(0);
  
  const handleOnEnded = useCallback((index) => {
    currentVideoClipIndex.current = currentVideoClipIndex.current + 1;
    
    if (currentVideoClipIndex.current < videoData.ai_video_clips.length) {
      for(let i = currentVideoClipIndex.current; i < videoData.ai_video_clips.length; i++) {
        if (videoData.ai_video_clips[i].ai_avatar_id === avatarIds[index]) {
          videoPlayerRefs[index].current.src = videoData.ai_video_clips[i].file_path;
          break;
        }
      }
      
      const nextAiVideoClip = videoData.ai_video_clips[currentVideoClipIndex.current];
      const nextPlayerIndex = avatarIds.indexOf(nextAiVideoClip.ai_avatar_id);
      videoPlayerRefs[nextPlayerIndex].current.play();
    }
  }, [videoData]);
  
  const initializeVideoPlayers = useCallback(() => {
    videoPlayerRefs.forEach((playerRef, index) => {
      for(const clip of videoData.ai_video_clips) {
        if (clip.ai_avatar_id === avatarIds[index]) {
          playerRef.current.src = clip.file_path;
          break;
        }
      }
    });
  }, [videoData]);
  
  const handlePlayPause = useCallback(() => {
    if (currentVideoClipIndex.current === videoData.ai_video_clips.length) {
      currentVideoClipIndex.current = 0;
      initializeVideoPlayers();
      
      const currentAiVideoClip = videoData.ai_video_clips[currentVideoClipIndex.current];
      const playerIndex = avatarIds.indexOf(currentAiVideoClip.ai_avatar_id);
      videoPlayerRefs[playerIndex].current.src = currentAiVideoClip.file_path;
      videoPlayerRefs[playerIndex].current.play();
      return;
    }
    
    const currentAiVideoClip = videoData.ai_video_clips[currentVideoClipIndex.current];
    const playerIndex = avatarIds.indexOf(currentAiVideoClip.ai_avatar_id);
    const isPlaying = videoPlayerRefs[playerIndex].current.paused;
    
    if (isPlaying) {
      videoPlayerRefs[playerIndex].current.play();
    } else {
      videoPlayerRefs[playerIndex].current.pause();
    }
    
  }, [videoData]);
  
  const handleNext = useCallback(() => {
    const currentClip = videoData.ai_video_clips[currentVideoClipIndex.current];
    const currentPlayerIndex = avatarIds.indexOf(currentClip.ai_avatar_id);
    const currentPlayer = videoPlayerRefs[currentPlayerIndex].current;
    
    if (!currentPlayer.paused) {
      currentPlayer.pause();
    }
    
    currentVideoClipIndex.current = currentVideoClipIndex.current + 1;
    
    if (currentVideoClipIndex.current < videoData.ai_video_clips.length) {
      for(let i = currentVideoClipIndex.current; i < videoData.ai_video_clips.length; i++) {
        if (videoData.ai_video_clips[i].ai_avatar_id === avatarIds[currentPlayerIndex]) {
          videoPlayerRefs[currentPlayerIndex].current.src = videoData.ai_video_clips[i].file_path;
          break;
        }
      }
      
      const nextAiVideoClip = videoData.ai_video_clips[currentVideoClipIndex.current];
      const nextPlayerIndex = avatarIds.indexOf(nextAiVideoClip.ai_avatar_id);
      
      videoPlayerRefs[nextPlayerIndex].current.play();
    }
  }, [videoData]);
  
  const handlePrevious = useCallback(() => {
    const currentClip = videoData.ai_video_clips[currentVideoClipIndex.current];
    const currentPlayerIndex = avatarIds.indexOf(currentClip.ai_avatar_id);
    const currentPlayer = videoPlayerRefs[currentPlayerIndex].current;
    
    if (!currentPlayer.paused) {
      currentPlayer.pause();
      currentPlayer.currentTime = 0;
    }
    
    if (currentVideoClipIndex.current > 0) {
      currentVideoClipIndex.current = currentVideoClipIndex.current - 1;
      const previousAiVideoClip = videoData.ai_video_clips[currentVideoClipIndex.current];
      const previousPlayerIndex = avatarIds.indexOf(previousAiVideoClip.ai_avatar_id);
      
      for(let i = currentVideoClipIndex.current; i >= 0; i--) {
        if (videoData.ai_video_clips[i].ai_avatar_id === avatarIds[previousPlayerIndex]) {
          videoPlayerRefs[previousPlayerIndex].current.src = videoData.ai_video_clips[i].file_path;
          break;
        }
      }
      
      videoPlayerRefs[previousPlayerIndex].current.play();
    }
  }, [videoData]);
  
  useEffect(() => {
    currentVideoClipIndex.current = 0;
    initializeVideoPlayers();
  }, [videoData]);
  
  
  return (
    <div className="video-dialogue-container" key={videoData.id}>
      <div className="row">
        {[...Array(noOfAvatars)].map((_, index) => (
          <div className="col" key={index}>
            <video ref={videoPlayerRefs[index]} controls={false} onEnded={() => handleOnEnded(index)} style={{width: '100%'}}>
              <source type="video/mp4"/>
            </video>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-12 text-center p-3">
          {videoData.no_of_avatars > 1 &&
            <button className="btn btn-outline-secondary mx-3" onClick={handlePrevious}>Previous</button>
          }
          <button className="btn btn-outline-primary mx-3" onClick={handlePlayPause}>Play / Pause</button>
          {videoData.no_of_avatars > 1 &&
            <button className="btn btn-outline-secondary mx-3" onClick={handleNext}>Next</button>
          }
        </div>
        <div className="row">
          {videoData.ai_video_clips.map((clip, index) => (
            <div key={index} className="script">
              <pre>
                <code>{`[avatar_${avatarIds.indexOf(clip.ai_avatar_id) + 1}]: `}</code>
                {clip.script}
              </pre>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
};

export default AiVideoClipsPlayer;
