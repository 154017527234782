// import * as $ from "jquery"
import * as bootstrap from 'bootstrap';

function initialize() {
  function createModalDiv() {
    const modalDiv = document.createElement("div");
    modalDiv.id = "js--modalDiv";
    modalDiv.classList.add("modal");
    modalDiv.setAttribute("tabindex", "-1");
    modalDiv.setAttribute("role", "dialog");

    // placeholder modal-dialog element required for bs5 at time of show() call
    const modalDialog = document.createElement("div");
    modalDialog.classList.add("modal-dialog");
    modalDiv.appendChild(modalDialog);

    document.querySelector("body").appendChild(modalDiv);
    return modalDiv;
  }

  function handleRemoteResponse(event) {
    const [data, _status, _xhr] = event.detail;
    if (data.clear_modal) {
        modalDiv.innerHTML = "";
    }
    if (data.redirect) {
      modalBootEle.hide();
      window.location = data.redirect;
    } else if (data.refresh) {
      window.location.reload();
    } else if (data) {
      modalDiv.innerHTML = data.querySelector(".modal").innerHTML;
      modalDiv.dispatchEvent(new CustomEvent("modal.load", { bubbles: true }));
      setupListeners(modalDiv);
    }
  }

  function handleRemoteError(event) {
    const [data, status, _xhr] = event.detail;
    if (status === "Unauthorized") {
      window.location.reload();
    } else {
      try {
        const response = JSON.parse(_xhr.responseText);
        const errorMessage = response.error || "An error occurred on the server. Please try again.";
        alert(errorMessage);
        if (response.close_modal) {
          modalBootEle.hide();
        }
      } catch (e) {
        Rollbar.error("Error parsing JSON response", e);
        alert("An error occurred on the server. Please try again.");
      }
    }
  }

  function setupListeners(modalDiv) {
    const form = modalDiv.querySelector("form")
    if (form) {
      form.dataset.remote = true;
    }

    modalDiv.querySelectorAll("[data-remote]").forEach(function(element) {
      element.addEventListener("ajax:success", handleRemoteResponse);
      element.addEventListener("ajax:error", handleRemoteError);
    });
  }

  const modalDiv = createModalDiv();
  const modalBootEle = new bootstrap.Modal(modalDiv);

  document.querySelectorAll(".js--modal").forEach(function(element) {
    element.dataset.remote = true;
    element.addEventListener("ajax:before", function() {
      modalDiv.innerHTML = "";
      modalBootEle.show();
    })
    element.addEventListener("ajax:success", handleRemoteResponse);
    element.addEventListener("ajax:error", handleRemoteError);
  });

  const params = new URLSearchParams(window.location.search);
  if (params.get("add_another") === "true") {
    const modalTrigger = document.querySelector(".js--modalOnAddAnother")
    modalTrigger && modalTrigger.click()
    // Remove the ?add_another= from the URL so manually refreshing the page doesn't
    // re-trigger the modal popup.
    window.history.replaceState(null, null, window.location.pathname);
  }
}

document.addEventListener("DOMContentLoaded", initialize);
document.addEventListener("turbo:render", initialize);
