import React from "react";
import AiVideoClipsPlayer from "./ai_video_clips_player";

const AiVideoPreviewWrapper = ({ videoData }) => {
  if (!videoData || Object.keys(videoData).length === 0) {
    return (
      <div className="alert alert-warning">
        Please select an AI Video from the list to preview it.
      </div>
    );
  }
  
  
  return <AiVideoClipsPlayer videoData={videoData}/>;
};

export default AiVideoPreviewWrapper;

