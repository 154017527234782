import React from "react";
import { createRoot } from 'react-dom/client';
import AiVideoPreviewWrapper from "./ai_video_preview_wrapper";

let currentVideo = {};
let root = null;

function renderComponentWithData(video) {
  const targetDomNode = document.getElementById("ai-video-preview");
  if (targetDomNode) {
    if (!root) {
      root = createRoot(targetDomNode);
    }
    root.render(<AiVideoPreviewWrapper videoData={video} />);
  }
}

function updateVideoDataAndRender(newVideo) {
  currentVideo = newVideo;
  renderComponentWithData(currentVideo);
}

window.updateVideoDataAndRender = updateVideoDataAndRender;

function initialize() {
  renderComponentWithData(currentVideo);
}

document.addEventListener("DOMContentLoaded", initialize);
document.addEventListener("turbo:render", initialize);
